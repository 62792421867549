import { createContext, useState } from "react";

type ImgaeContextType = {
  blob: Blob | null;
  setBlob: React.Dispatch<React.SetStateAction<Blob | null>>;
};

export const ImgContext = createContext({} as ImgaeContextType);

type ImgContextProviderType = {
  children: React.ReactNode;
};

export const ImgProvider = ({ children }: ImgContextProviderType) => {
  const [blob, setBlob] = useState<Blob | null>(null);
  return (
    <ImgContext.Provider value={{ blob, setBlob }}>
      {children}
    </ImgContext.Provider>
  );
};
