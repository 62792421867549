// export const getUser = async (phone: string) => {
//   try {
//     const response = await fetch(`https://clearhesangkhoai.com:8002/api/user/vinaphone/info/${phone}`, {
//       method: "GET",
//       headers: {
//         "Content-Type": "application/json",
//       },
//     });
//     if (!response.ok) {
//       console.log("User not exists");
//       return null; //User not exists
//     }
//     const data: IResponse = await response.json();
//     console.log("getuser =>>>", data.data);
//     return data.data;
//   } catch (error) {
//     console.error("Error fetching user data:", error);
//     return null;
//   }
// }

export const addUser = async (name: string, phone: string, email: string) => {
  try {
    const response = await fetch((process.env.REACT_APP_API_REGISTER || "http://localhost:8002") + "/api/user/clear/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, phone, email }),
    });

    let data = await response.json();
    return data;

  } catch (error) {
    console.error("Error adding user:", error);
  }
  return null;
}
