import { BrowserRouter, Routes, Route } from "react-router-dom";
import Register from "./pages/register";
import Kv from "./pages/kv";
import Camera from "./pages/camera";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/">
          <Route index element={<Kv />} />
          <Route path="/register" element={<Register />} />
          <Route path="/camera" element={<Camera />} />
          {/* <Route path="*" element={<NoPage />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
