import React, { useContext, useState } from "react";
import { handleButtonGroup } from "../functions";
import { ImgContext } from "../../../context/ImgContext";
interface ButtonCustomProps {
  urlOfButton: string;
  altOfButton: string;
  text: string;
  type: "download" | "share" | "again";
}

const ButtonCustom = (props: ButtonCustomProps) => {
  const [isClicked, setIsClicked] = useState(false);
  const imgContext = useContext(ImgContext);

  const handleClick = ({ type }: { type: "download" | "share" | "again" }) => {
    setIsClicked(true);
    setTimeout(() => {
      setIsClicked(false);
    }, 200);
    switch (type) {
      case "download":
        handleButtonGroup.download(imgContext.blob);
        break;
      case "share":
        handleButtonGroup.share(imgContext.blob);
        break;
      case "again":
        handleButtonGroup.again();
        break;

      default:
        console.log("Error");
        break;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "0px",
      }}>
      <img
        onClick={() => handleClick({ type: props.type })}
        style={{
          maxWidth: "100px",
          width: "100%",
          transform: isClicked ? "scale(1.1)" : "scale(1.0)",
          transition: "transform 0.1s",
        }}
        src={props.urlOfButton}
        alt={props.altOfButton}
      />
      <p
        style={{
          color: "white",
          fontSize: "15px",
          margin: "0px",
        }}>
        {props.text}
      </p>
    </div>
  );
};

const ButtonGroup = () => {
  return (
    <>
      <div style={{ display: "flex", gap: "13px" }}>
        <ButtonCustom
          type="download"
          urlOfButton="./imgs/camera/download.png"
          altOfButton="download"
          text="Tải xuống ngay"
        />
        <ButtonCustom
          type="share"
          urlOfButton="./imgs/camera/share.png"
          altOfButton="share"
          text="Nhận thêm quà"
        />
        <ButtonCustom
          type="again"
          urlOfButton="./imgs/camera/again.png"
          altOfButton="again"
          text="Chụp lại"
        />
      </div>
    </>
  );
};

export default ButtonGroup;
