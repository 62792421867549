import React from "react";
import "../styles.css"
interface IInputProps {
  handleChangeEvent: (e: React.ChangeEvent<HTMLInputElement>) => void;
  srcText: string;
  type?: string;
  hight?: string;
}

const Input = (prop: IInputProps) => {
  return (
    <>
      <div
        style={{
          width: "80%",
          marginTop: "15px",
          display: "flex",
          flexDirection: "column",
        }}>
        <div>
          <img
            src={prop.srcText}
            alt="name"
            height={prop.hight || "18px"}
            width={"auto"}
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            autoComplete="off"
            type={prop.type || "text"}
            name="name"
            onChange={prop.handleChangeEvent}
            style={{
              width: "100%",
              backgroundImage: "url(./imgs/shape_input.png)",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              border: "none",
              backgroundColor: "transparent",
              height: "40px",
              textAlign: "center",
              fontSize: "25px",
              color: "white",
              marginTop: "8px",
              outline: "none",
              boxShadow: "none",
              
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Input;
