import saveAs from "file-saver";

const handleButtonDownload = (blob: Blob | null) => {
   if (!blob) {
      console.error("Blob is null");
      return;
   }
   const options = { timeZone: 'Asia/Bangkok', hour12: false };
   let date = new Date().toLocaleString('vi-Vi', options);
   date = date.replace(/\//g, "");
   date = date.replace(/\s/g, "-");
   date = date.replace(/:/g, "");
   console.log(date);
   saveAs(blob, `${date}.jpg`);
}

const handleButtonShare = async (blob: Blob | null) => {
   if (!blob) {
      console.error("Blob is null");
      return;
   }
   const image = new File([blob], 'image.jpg', { type: 'image/jpeg' });

   if (!!navigator.share) {
      console.log("Web Share API is supported!");
   } else {
      console.log("Web Share API is not supported in this browser.");
   }

   if (navigator.share) {
      try {
         await navigator.share({
            title: 'Chia sẻ hình ảnh',
            text: 'Xem hình ảnh này!',
            files: [image],
         });
         console.log('Chia sẻ thành công');
      } catch (error) {
         console.error('Chia sẻ thất bại:', error);
      }
   } else {
      alert('Trình duyệt của bạn không hỗ trợ chia sẻ');
   }
}

const handleButtonAgain = () => {
   return window.location.reload();
}

//TODO : write function to handle button
export const handleButtonGroup = {
   download: handleButtonDownload,
   share: handleButtonShare,
   again: handleButtonAgain
}; 