import { useState } from "react";
import CaptureWithFrame from "./component/capture/CaptureWithFrame";
import ShareWithCaptuer from "./component/share/ShareWithCaptuer";
import { ImgProvider } from "./context/ImgContext";
const Camera = () => {
  const [handleShow, setHandleShow] = useState(true);
  return (
    <>
      <ImgProvider>
        {handleShow ? (
          <CaptureWithFrame setHandleShow={setHandleShow} />
        ) : (
          <ShareWithCaptuer />
        )}
      </ImgProvider>
    </>
  );
};

export default Camera;
