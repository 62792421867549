import React from "react";
import { useNavigate } from "react-router-dom";

const KV = () => {
  const navigation = useNavigate();
  const handleButtonClick = () => {
    navigation("/register");
  };
  return (
    <>
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100vh",
        }}>
        <div
          style={{
            zIndex: 1,
            textAlign: "center",
            position: "absolute",
            width: "100%",
            top: "148vw",
          }}>
          <input
            type="button"
            onClick={handleButtonClick}
            style={{
              backgroundImage: "url('./imgs/kv_nut.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "100% 100%",
              border: "none",
              backgroundColor: "transparent",
              width: "200px",
              height: "70px",
            }}
            onTouchStart={(e) => {
              e.currentTarget.style.transform = "scale(0.95)";
            }}
            onTouchEnd={(e) => {
              e.currentTarget.style.transform = "scale(1)";
            }}
          />
        </div>

        <img
          src="./imgs/kv_bg.png"
          alt=""
          style={{
            zIndex: -1,
            width: "100%",
            position: "absolute",
          }}
        />
      </div>
    </>
  );
};

export default KV;
