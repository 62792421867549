export const isName = (name: string): boolean => {
  if (!name) {
    return false;
  }
  if (name.length < 5 || name.length > 50) {
    return false;
  }
  if (!/^[a-zA-ZaáàảãạeéèẻẽẹiíìỉĩịoóòỏõọuúùủũụỳýỷỹỵăắằẳẵặâấầẩẫậêếềểễệôốồổỗộơớờởỡợưứừửữựđAÁÀẢÃẠEÉÈẺẼẸIÍÌỈĨỊOÓÒỎÕỌUÚÙỦŨỤỲÝỶỸỴĂẮẰẲẴẶÂẤẦẨẪẬÊẾỀỂỄỆÔỐỒỔỖỘƠỚỜỞỠỢƯỨỪỬỮỰĐ ]+$/.test(name)) {

    return false;
  }
  return true;
}

export const isPhone = (phone: string): boolean => {
  if (!phone) {
    return false;
  }
  if (phone.length < 9 || phone.length > 11) {
    return false;
  }
  if (!/^0\d+$/.test(phone)) {
    return false;
  }
  return true;
}

export const isEmail = (email: string): boolean => {
  if (!email) {
    return false;
  }
  const emailParts = email.split('@');
  if (emailParts[0].length <= 5) {
    return false;
  }
  if (email.length < 15 || email.length > 100) {
    return false;
  }
  if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.]+\.[a-zA-Z]{2,}$/.test(email)) {
    return false;
  }
  return true;
}