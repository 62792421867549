import React, { useRef, useEffect, useState, useContext } from "react";
import Webcam from "react-webcam";
import html2canvas from "html2canvas";
import { ImgContext } from "../../context/ImgContext";

const CaptureWithFrame = ({
  setHandleShow,
}: {
  setHandleShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const webcamRef = useRef<Webcam>(null);
  const captureRef = useRef<HTMLDivElement>(null);
  // const videoRef = useRef<HTMLVideoElement>(null);
  const [cameraReady, setCameraReady] = useState(false);
  const [loading, setLoading] = useState(true);
  const imgContext = useContext(ImgContext);

  const isWebRTCSupported = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
  };

  useEffect(() => {
    const initCamera = async () => {
      if (isWebRTCSupported()) {
        await navigator.mediaDevices
          .getUserMedia({
            video: {
              facingMode: "environment",
            },
          })
          .then(() => {
            setCameraReady(true);
            setLoading(false);
          })
          .catch((error) => {
            console.error("Camera error:", error);
            alert("Camera error, please try again.");
            setLoading(false);
          });
      } else {
        console.error("WebRTC is not supported in this browser.");
        alert("WebRTC is not supported in this browser.");
        setLoading(false);
      }
    };
    initCamera();

    // return () => {
    //   if (webcamRef.current?.stream) {
    //     webcamRef.current.stream.getTracks().forEach((track) => {
    //       track.stop();
    //     });
    //   }
    // };
  }, []);

  // useEffect(() => {
  //   const getCameraStream = async () => {
  //     try {
  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         video: {
  //           facingMode: "environment",
  //         },
  //       });
  //       if (videoRef.current) {
  //         videoRef.current.srcObject = stream;
  //       }
  //     } catch (error) {
  //       console.error("Error accessing camera:", error);
  //     }
  //   };

  //   getCameraStream();
  // }, []);

  const handleCapture = () => {
    const captureElement = captureRef.current;

    if (!captureElement) {
      console.error("Capture element is null");
      return;
    }

    html2canvas(captureElement, { scale: 1 })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          if (blob) {
            imgContext.setBlob(blob);
            setHandleShow(false);
          }
        });
      })
      .catch((err) => {
        console.error("Error capturing image", err);
        alert("Failed to capture image. Please try again.");
      });
  };

  const handleButton = () => {
    handleCapture();
  };

  const videoConstraints = {
    // Chọn camera sau (nếu có) thay vì camera trước "user" (mặc định)
    facingMode: { ideal: "environment" },
  };

  return (
    <div>
      <div
        id="capture"
        ref={captureRef}
        style={{
          position: "relative",
          width: "100vw",
          height: window.innerHeight,
          margin: "auto",
          overflow: "hidden",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        {loading ? (
          <p>Loading camera...</p>
        ) : cameraReady ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            // screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
            // forceScreenshotSourceSize={true}
            onUserMediaError={(error) => console.error(error)}
            disablePictureInPicture={false}
            controls={false}
            playsInline={true}
            style={{
              width: "auto",
              height: "100%",
              objectFit: "cover",
            }}
          />
        ) : (
          // <video ref={videoRef} autoPlay playsInline />
          <p>Camera is not available</p>
        )}

        <div
          style={{
            position: "absolute",
            width: "100vw",
            height: window.innerHeight,
          }}>
          <img
            src="./imgs/camera/bg_camera.png"
            alt="Frame"
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              pointerEvents: "none",
              zIndex: 2,
            }}
          />
          <img
            src="./imgs/camera/ice-clear.png"
            alt="ice-clear"
            style={{
              position: "absolute",
              bottom: "0",
              width: "110%",
              height: "auto",
            }}
          />
        </div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "5%",
          right: "20%",
          transform: "translateX(50%)",
          cursor: "pointer",
          zIndex: 3,
        }}>
        <img
          onClick={handleButton}
          style={{ width: "60px" }}
          src="./imgs/camera/nutchup.png"
          alt="Capture button"
        />
      </div>
    </div>
  );
};

export default CaptureWithFrame;
