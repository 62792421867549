import React, { useState } from "react";
import { addUser } from "./components/fetch";
import Input from "./components/input";
import { isEmail, isName, isPhone } from "./components/checker";

const Register = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [isAgree, setIsAgree] = useState(false);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleButtonClick = async () => {
    if (name === "test" || phone === "01234567891") {
      return window.location.href = `https://clearhesangkhoai.com/argame?code=testCode`;
    }
    if (name === "" || phone === "" || email === "") {
      return alert("Vui lòng nhập đầy đủ thông tin");
    }
    if (!isAgree) {
      return alert("Vui lòng đồng ý với điều khoản của chúng tôi");
    }

    if (!isName(name)) {
      return alert("Tên không hợp lệ. Tên phải từ 6 đến 50 ký tự");
    }

    if (!isPhone(phone)) {
      return alert(
        "Số điện thoại không hợp lệ. Phải đúng định dạng, số điện thoại phải từ 10 đến 11 số"
      );
    }

    if (!isEmail(email)) {
      return alert("Email không hợp lệ");
    }

    const result = await addUser(name, phone, email);

    switch (result.code) {
      case 10001:
        alert("Số điện thoại đã được đăng ký");
        break;
      case 10002:
        alert("Hơi buồn chút xíu, bạn đã chậm chân rồi");
        break;
      case 10003:
        let qrCode = result.data.qrCode.toString();
        window.location.href = `https://clearhesangkhoai.com/argame?code=${qrCode}`;
        break;
      default:
        alert("Đăng ký không thành công");
        break;
    }
    // alert("Đăng ký thành công");
  };

  return (
    <>
      <div
        id="register-form"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100vw",
          backgroundImage: "url('./imgs/bg.png')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center top",
        }}>
        <div
          style={{
            position: "absolute",
            width: "90%",
            top: "50vw",
          }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <Input
              handleChangeEvent={handleNameChange}
              srcText="./imgs/text_name.png"
              hight="17px"
            />
            <Input
              type="number"
              handleChangeEvent={handlePhoneChange}
              srcText="./imgs/text_phone.png"
              hight="18px"
            />
            <Input
              handleChangeEvent={handleEmailChange}
              srcText="./imgs/text_email.png"
              hight="15px"
            />
          </div>
          <div
            style={{
              marginTop: "20px",
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}>
            <div
              style={{
                textAlign: "justify",
                width: "80%",
              }}>
              <div
                style={{
                  width: "20px",
                  position: "relative",
                  transform: "translate(0px, 20px)",
                }}>
                <img
                  src="./imgs/shape_check.png"
                  alt=""
                  style={{ marginRight: "10px", position: "absolute" }}
                  height={"30px"}
                  onClick={() => {
                    console.log(isAgree);
                    setIsAgree(true);
                  }}
                />
                <img
                  src="./imgs/check.png"
                  alt=""
                  style={{
                    position: "absolute",
                    display: isAgree ? "block" : "none",
                    // transform: "translate(10px, 0px)",
                  }}
                  height={"30px"}
                />
              </div>
              <p
                style={{
                  color: "white",
                  fontSize: "15px",
                  transform: "translate(40px)",
                }}>
                Tôi đồng ý nhận thông tin tiếp thị, <br></br> quảng cáo và khảo
                sát của Unilever.
              </p>
              <div>
                <p style={{ color: "white", fontSize: "14px" }}>
                  Bằng việc đăng ký, tôi xác nhận trên 16 tuổi và đã đọc thông
                  báo về <a href="https://www.unilevernotices.com/privacy-notices/vietnam-vietnamese.html" style={{ color: "aqua" }}>Quyền riêng tư</a> và{" "}
                  <a
                    href="https://www.unilevernotices.com/cookie-notices/vietnam-vietnamese.html"
                    style={{ color: "aqua" }}>
                    thông báo cookies
                  </a>{" "}
                  của Unilever để hiểu cách Unilever sử dụng thông tin của tôi.{" "}
                </p>
              </div>
            </div>
          </div>

          <div style={{ textAlign: "center" }}>
            <input
              type="button"
              onClick={handleButtonClick}
              style={{
                backgroundImage: "url('./imgs/button.png')",
                backgroundRepeat: "no-repeat",
                backgroundSize: "100% 100%",
                border: "none",
                backgroundColor: "transparent",
                height: "70px",
                width: "200px",
              }}
              onTouchStart={(e) => {
                e.currentTarget.style.transform = "scale(0.95)";
              }}
              onTouchEnd={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
