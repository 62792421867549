import { useContext, useEffect, useState } from "react";
import ButtonGroup from "./components/ButtonGroup";
import { ImgContext } from "../../context/ImgContext";

const ShareWithCaptuer = () => {
  const imgBlob = useContext(ImgContext);
  const [imgURL, setImgURL] = useState<string | null>(null);

  // const imgURL = "./imgs/kv_bg.png";

  useEffect(() => {
    if (imgBlob.blob) {
      const url = URL.createObjectURL(imgBlob.blob);
      setImgURL(url);
    }
  }, [imgBlob.blob]);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}>
        <div style={{ position: "absolute" }}>
          <img
            src="./imgs/camera/bg.jpg"
            alt=""
            style={{
              width: "100vw",
              height: "100vh",
            }}
          />
        </div>
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            width: "100vw",
            height: "100vh",
          }}>
          <div
            style={{
              position: "relative",
              width: window.innerWidth,
              height: window.innerHeight,
            }}>
            <img
              src="./imgs/camera/Rectangle.png"
              alt="Rectangle"
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
              }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                zIndex: 2,
                paddingTop: "10%",
              }}>
              {imgURL ? (
                <img
                  src={imgURL}
                  alt="img"
                  style={{
                    position: "absolute",
                    width: "auto",
                    height: "75%",
                    left: "50%",
                    transform: "translateX(-50%)",
                  }}
                />
              ) : (
                <p>No image capture yet.</p>
              )}
            </div>
            <div
              style={{
                position: "absolute",
                zIndex: 2,
                bottom: "5%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}>
              <ButtonGroup />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareWithCaptuer;
